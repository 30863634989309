"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";

export default function GATracking({}) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    gtag("event", "page_view", {});
    return () => {};
  }, [pathname, searchParams]);

  return <></>;
}
